<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!-- 搜索框 -->
			<div class="grabble">
				<div style="width: 85%;display: flex;">
					<!-- 会员姓名 -->
					<el-input class="hunt" v-model="input" placeholder="请输入会员姓名" size="large" @input="orangutan" clearable />
					<!-- 会员编码 -->
					<el-input class="hunt" v-model="bianma" placeholder="请输入会员编码" size="large" @input="orangutan" clearable />
					<!-- 组织名称 -->
					<el-select class="hunt" @focus="closeIt" v-model="ability" placeholder="请选择组织名称" size="large"
						@click="multiple" clearable @change="numerical" ref="selectIt" />
					<!-- 是否缴费 -->
					<el-select class="hunt" v-model="values" placeholder="请选择是否缴费" clearable @clear="empty"
						size="large">
						<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
						<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
					</el-select>
					<!-- 月份范围 -->
					<el-date-picker v-model="value2" type="month" size="large" placeholder="请选择时间" @change="insuch" />
					<!-- 会员状态 -->
					<el-select style="margin-left: 10px;" v-model="values1" placeholder="请选择会员状态" clearable
						@clear="empty" size="large">
						<el-option label="正常" value="1" style="width:100%; text-align-last: center;"></el-option>
						<el-option label="归档" value="2" style="width:100%; text-align-last: center;"></el-option>
					</el-select>
					<el-button style="margin-left: 10px" type="primary" size="large" @click="search1"
						:loading='formative'><el-icon class="cancelgu">
							<Search />
						</el-icon>搜索</el-button>
				</div>
				<!-- <div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div> -->
			</div>
			<div class="summation">
				<div class="hint">统计 : {{totality}}条</div>
				<div class="setting">
					<!-- 设置规则 -->
					<div>
						<el-button style="margin-left: 10px" type="primary" size="default"
							@click="regulation">设置规则</el-button>
					</div>
					<!-- 设置缴费参数 -->
					<!-- <div>
              <el-button
                style="margin-left: 10px"
                type="primary"
                size="default"
                @click="parameter"
                >设置缴费参数</el-button
              >
            </div> -->
					<!-- 设置缴费 -->
					<div>
						<el-button style="margin-left: 10px" type="primary" size="default"
							@click="payment">设置缴费</el-button>
					</div>
					<!--  导出 -->
					<el-dropdown style="margin-left: 12px;" loading split-button type="primary" @command="handleCommand"
						:hide-on-click="around">
						<div class="loading" v-show="wrought"></div>

						导出
						<template #dropdown>
							<el-dropdown-menu style="width: 117px;">
								<el-dropdown-item command="personage">按月导出</el-dropdown-item>
								<el-dropdown-item command="personage1">按年导出明细</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</div>
			</div>
			<el-table :data="filterTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain" border
				:header-cell-style="{ background: '#F5F7FA' }" :empty-text="texts">
				<!-- 会员姓名 -->
				<el-table-column fixed label="会员姓名" prop="name" align="center" :show-overflow-tooltip='true'
					width="120">
				</el-table-column>
				<!-- 会员编码 -->
				<el-table-column label="会员编码" prop="username" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>
				<!-- 所属工会 -->
				<el-table-column label="所属工会" prop="unionName" align="center" :show-overflow-tooltip='true' width="150">
				</el-table-column>
				<!-- 所属部门 -->
				<el-table-column label="所属部门" prop="departmentName" align="center" :show-overflow-tooltip='true'
					width="120">
				</el-table-column>
				<!-- 缴费金额 -->
				<el-table-column label="缴费金额" prop="cost" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>
				<!-- 是否缴费 -->
				<el-table-column label="是否缴费" prop="isPay" align="center" :show-overflow-tooltip='true' width="120">
				</el-table-column>
				<!-- 按年/月 -->
				<el-table-column align="center" width="120" label="按年/月" :show-overflow-tooltip='true'
					prop="dateType" />
				<!-- 所缴日期 -->
				<el-table-column align="center" width="120" label="所缴日期" :show-overflow-tooltip='true' prop="payDate" />
				<!-- 缴费时间 -->
				<el-table-column align="center" width="120" label="缴费时间" :show-overflow-tooltip='true' prop="payTime" />
				<!-- 缴费类型 -->
				<el-table-column align="center" width="120" label="缴费类型" :show-overflow-tooltip='true' prop="payType" />
				<!-- 实际缴费人 -->
				<el-table-column align="center" width="120" label="实际缴费人" :show-overflow-tooltip='true'
					prop="payName" />
				<!-- 会员状态 -->
				<el-table-column align="center" width="120" label="会员状态" :show-overflow-tooltip='true' prop="status" />
				<!-- 补缴时间 -->
				<!-- <el-table-column align="center" label="补缴时间" :show-overflow-tooltip='true' prop="isEnable" /> -->
				<el-table-column label="操作" align="center" width="240">
					<template #default="scope">
						<el-button size="default" @click.stop="copyreader(scope.row.userId)" class="bulur"><el-icon
								class="cancel">
								<Search />
							</el-icon>记录查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 表格分页 -->
			<!-- 一页时候隐藏 hide-on-single-page="true" -->
			<el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :current-page="currentPage"
				:page-sizes="[6, 12, 18, 24]" :page-size="pagesize" :total="totality" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" />
		</el-card>
		<!-- 删除按钮弹窗 -->
		<el-dialog v-model="remove" title="删除" width="30%" center>
			<div class="labor">
				<div class="sure">确定删除吗？</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button class="asd" size="large" type="primary" @click="affirm">确定</el-button>
					<el-button class="dsa" size="large" @click="remove=false">取消</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 所属组织弹窗 -->
		<div>
			<s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
			</s3-layer>
		</div>
		<div>
			<s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
				<div class="task">
					{{quoth}}
				</div>
			</s3-layer>
		</div>
	</div>
</template>
<script>
	import {
		Search,
		Setting
	} from "@element-plus/icons-vue";
	import {
		useRouter
	} from "vue-router";
	import {
		ref,
		onMounted
	} from 'vue'
	import {
		HFlist,
		Zzzlist,
		HFmonthly,
		HFyear
	} from '../utils/api'
	// import moments from 'moment'
	import qs from 'qs'
	import {
		ElMessage
	} from "element-plus";
	export default {
		name: "payment",
		setup() {
			// 导出接口成功字段
			let quoth = ref('')
			// 按年导出接口
			const detail = () => {
				console.log('会员姓名', input.value)
				console.log('组织Hcode', Hcode.value)
				console.log('输入会员编码值', bianma.value)
				console.log('会员状态', values1.value)
				console.log('是否缴费', values.value)
				let now = new Date();
				let nowYear = now.getFullYear(); //当前年
				let nowMonth = now.getMonth(); //当前月
				let firstDay = new Date(nowYear, nowMonth, 1).getTime()
				if (value2.value !== '' && value2.value !== null) {
					itemss.value = value2.value.getTime() / 1000
				} else {
					itemss.value = ''
				}
				console.log('选择月份', itemss.value)
				let data = {
					limit: branches.value,
					page: currentPage.value,
					name: input.value,
					username: bianma.value,
					unionCode: Hcode.value,
					isPay: values.value,
					startTime: itemss.value,
					userState: values1.value
				}
				HFyear(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						anew.value = true
						quoth.value = '任务已提交，请在右侧查看进度'
					} else {
						anew.value = false
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}
			// 按月导出接口
			const payout = () => {
				console.log('会员姓名', input.value)
				console.log('组织Hcode', Hcode.value)
				console.log('输入会员编码值', bianma.value)
				console.log('会员状态', values1.value)
				console.log('是否缴费', values.value)
				let now = new Date();
				let nowYear = now.getFullYear(); //当前年
				let nowMonth = now.getMonth(); //当前月
				let firstDay = new Date(nowYear, nowMonth, 1).getTime()
				if (value2.value !== '' && value2.value !== null) {
					itemss.value = value2.value.getTime() / 1000
				} else {
					itemss.value = ''
				}
				console.log('选择月份', itemss.value)
				let data = {
					limit: branches.value,
					page: currentPage.value,
					name: input.value,
					username: bianma.value,
					unionCode: Hcode.value,
					isPay: values.value,
					startTime: itemss.value,
					userState: values1.value
				}
				HFmonthly(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						anew.value = true
						quoth.value = '任务已提交，请在右侧查看进度'
					} else {
						anew.value = false
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}
			// 导出提示弹窗
			const anew = ref(false)
			// 导出按钮选择
			const handleCommand = (command) => {
				console.log(command)
				if (command == 'personage') {
					payout()
				} else if (command == 'personage1') {
					detail()
				}
			}
			// 设置会费参数跳转
			const parameter = () => {
				router.push('/HFargu')
			}
			// 设置规则跳转
			let regulation = () => {
				router.push('/HFregulation')
			}
			// 设置缴费跳转
			let payment = () => {
				router.push('/HFset')
			}
			let selectIt = ref()
			// 下拉隐藏
			let closeIt = () => {
				selectIt.value.blur();
			}
			// 选择组织名称变化
			let numerical = () => {
				// if(input.value==''&&ability.value==''&&values.value==''){
				//   list()
				// }
				Hcode.value = ''
			}
			// 获取会员编码值
			const bianma = ref('')
			// 选择月份
			const value2 = ref('')
			// 会员状态
			const values1 = ref('')
			// 选择月份时间转换
			let itemss = ref('')
			// 搜索加载
			const formative = ref(false)
			const search1 = () => {
				formative.value = true
				search()
			}
			// 搜索按钮
			let search = () => {
				console.log('会员姓名', input.value)
				console.log('组织Hcode', Hcode.value)
				console.log('输入会员编码值', bianma.value)
				console.log('会员状态', values1.value)
				console.log('是否缴费', values.value)
				let now = new Date();
				let nowYear = now.getFullYear(); //当前年
				let nowMonth = now.getMonth(); //当前月
				let firstDay = new Date(nowYear, nowMonth, 1).getTime()
				if (value2.value !== '' && value2.value !== null) {
					itemss.value = value2.value.getTime() / 1000
				} else {
					itemss.value = ''
				}
				console.log('选择月份', itemss.value)
				let data = {
					limit: branches.value,
					page: currentPage.value,
					name: input.value,
					username: bianma.value,
					unionCode: Hcode.value,
					isPay: values.value,
					startTime: itemss.value,
					userState: values1.value
				}
				HFlist(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						formative.value = false
						// 列表总数
						totality.value = res.data.data.page.totalCount
						// 列表数据
						filterTableData.value = res.data.data.page.list
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					} else {
						educe.value = false
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
					console.log(res)
				})
			}
			// 是否启用
			let values = ref('')
			// 标题内容
			let input = ref('')
			// 搜索框弹窗
			// 获取code
			let Hcode = ref('')
			// 获取选项
			let ability = ref('')
			let dendrogram = (node) => {
				ability.value = node.unionName
				Hcode.value = node.unionCode
				console.log(node)
			}
			let visible = ref(false)
			let multiple = () => {
				visible.value = true
			}
			// 搜索框
			let texture = ref([])
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			// 获取所有组织列表
			let own = () => {
				Zzzlist().then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						texture.value = res.data.data.allUnionList
					}
				})
			}
			// 加载中 暂无数据列表
			let texts = ref('加载中')
			onMounted(() => {
				list()
				// 所有组织列表接口
				own()
			})
			//  时间转换
			// let tiems=(row, colum,cellValue )=>{
			// if(typeof cellValue=='number'){
			//   return moments(cellValue*1000).format().substring(0,10)
			// }
			// }
			// 分页处理
			let currentPage = ref(1)
			let pagesize = ref(6)
			let handleSizeChange = (size) => {
				pagesize.value = size
				branches.value = size
				if (input.value !== '' || bianma.value !== '' || ability.value !== '' || values.value !== '' || values1
					.value !== '' || value2.value !== '') {
					search()
				} else {
					list()
				}
			}
			let handleCurrentChange = (size) => {
				currentPage.value = size
				if (input.value !== '' || bianma.value !== '' || ability.value !== '' || values.value !== '' || values1
					.value !== '' || value2.value !== '') {
					search()
				} else {
					list()
				}
			}
			// 数据增加
			let branches = ref(6)
			// 数据总数
			let totality = ref(null)
			// 获取人物列表
			let list = () => {
				let data = {
					limit: branches.value,
					page: currentPage.value,
				}
				HFlist(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						// 列表总数
						totality.value = res.data.data.page.totalCount
						// 列表数据
						filterTableData.value = res.data.data.page.list
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					}
				})
			}
			// 编辑跳转
			const copyreader = (id) => {
				console.log(id)
				sessionStorage.setItem('HFrecord', id)
				router.push({
					name: 'HFrecord',
					params: {
						UserId: 1
					}
				});
			}
			// 路由跳转
			const router = useRouter()
			// 列表数据循环
			const filterTableData = ref([])
			return {
				search1,
				formative,
				// 导出接口成功字段
				quoth,
				// 导出按钮选择
				handleCommand,
				// 导出按钮弹窗
				anew,
				values1,
				value2,
				bianma,
				// 会费缴纳参数按钮跳转
				// parameter,
				// 设置规则跳转
				regulation,
				payment,
				// 设置提示弹窗
				// form,
				// 是否启用
				values,
				selectIt,
				closeIt,
				// affirm,
				// 删除弹窗
				// remove,
				// 删除按钮
				// expurgate,
				numerical,
				// orangutan,
				// 标题内容
				input,
				// 搜索按钮
				search,
				// 搜索框
				ability,
				dendrogram,
				visible,
				multiple,
				defaultProps,
				texture,
				// 分页处理
				handleCurrentChange,
				handleSizeChange,
				currentPage,
				pagesize,
				// 加载中 暂无数据
				texts,
				// tiems,
				// 列表总数
				totality,
				// 编辑跳转
				copyreader,
				// 循环列表数据
				filterTableData
			};
		},
		components: {
			Search,
			Setting
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .el-form {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

	}

	.setting {
		display: flex;
	}

	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	.summation {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: 10px;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 150px);
		overflow: auto;
	}

	.hint {
		font-size: 14px;
		color: #909399;
		// font-weight: bold;
		margin-bottom: 15px;
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #EBEEF5 !important;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 22%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	// ::v-deep .el-scrollbar__thumb {
	//   display: none !important;
	// }
</style>
<style>
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	/* .el-scrollbar__thumb {
  display: none !important;
} */
	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>